<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="bomsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            :item-class="itemRowBackground"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">BILL OF MATERIALS</h1>
                        <v-btn
                            v-if="$route.name !== 'requested-boms'"
                            rounded
                            color="primary"
                            @click="openFindBOM"
                        >
                            <v-icon class="mr-1">
                                mdi-database-search
                            </v-icon>
                            FIND
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADER FILTER-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.index`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.code`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.code }}</p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0" v-if="item.creator">
                        {{ item.creator.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0" v-if="item.project">
                        {{
                            item.project.reference
                                ? `${item.project.reference} - ${item.project.name}`
                                : item.project.id
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            formatDate(
                                item.createdOn.seconds ||
                                    item.createdOn._seconds
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.deliveries`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <p class="my-0 text-center" v-on="on">
                            {{ item.totalDelivered }}/{{ item.totalQty }} ({{
                                (
                                    (item.totalDelivered / item.totalQty) *
                                    100
                                ).toFixed(0)
                            }}%)
                        </p>
                    </template>
                    <span>
                        Total Qty:
                        {{ item.totalQty }}<br />
                        Delivered:
                        {{ item.totalDelivered }}<br />
                        Remaining:
                        {{ item.totalRemaining }}<br />
                    </span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.notes }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openProcessBOM(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="validateQuantity(item) && sendBOMPermission"
                        small
                        icon
                        @click="openPartials(item)"
                    >
                        <v-icon>mdi-cube-send</v-icon>
                    </v-btn>
                    <div v-if="item.deliver || item.partialsRejectionHistory">
                        <v-menu rounded offset-y>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    depressed
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-if="item.deliver"
                                    @click.stop="openRejectPartials(item)"
                                >
                                    <v-list-item-title>
                                        Reject Partials
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click.stop="openRejected(item)"
                                    v-if="item.partialsRejectionHistory"
                                >
                                    <v-list-item-title>
                                        Show Partials Rejection Reasons
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS BOM-->
        <v-dialog
            v-model="processBOM"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessBOM
                v-if="processBOM"
                :bom="selectedBOM"
                :process="processName"
                @close="closeProcessBOM"
                @approve="approve"
                @closeBOM="closeBOM"
                @sendToDeliver="sendToDeliver"
                @replaceItems="replaceItems"
                @partialsAlert="partialsAlert"
            />
        </v-dialog>
        <!--FIND BOM-->
        <v-dialog
            v-model="findBOM"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindBOM v-if="findBOM" @addBOM="addBOM" @close="closeFindBOM" />
        </v-dialog>
        <!--SEND FOR DELIVERY-->
        <v-dialog :retain-focus="false" persistent v-model="partialsForm">
            <SendPartials
                v-if="partialsForm"
                :bom="selectedBOM"
                @replaceItems="replaceItems"
                @partialsAlert="partialsAlert"
                @close="closePartials"
            />
        </v-dialog>
        <!--SEND FOR DELIVERY-->
        <v-dialog
            max-width="600px"
            :retain-focus="false"
            persistent
            v-model="rejectPartialsForm"
        >
            <v-card :loading="rejectLoading" v-if="rejectPartialsForm">
                <v-card-title class="text-h5">Reject Partials</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject the
                            partial requests
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectPartials">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectPartials"
                        text
                        color="error"
                        :loading="rejectLoading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectionTable
                v-if="rejectionTable"
                :items="selectedBOM.partialsRejectionHistory"
                @closeDialog="closeRejected"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import { auth } from '@/services/firebase'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'ApprovedBOM',
    components: {
        ProcessBOM: () => import('@/components/WorkOrders/ProcessBOM.vue'),
        FindBOM: () => import('@/components/WorkOrders/FindBOM.vue'),
        SendPartials: () => import('@/components/WorkOrders/SendPartials.vue'),
        RejectionTable: () =>
            import('@/components/WorkOrders/RejectionBOMTable.vue'),
    },
    data: () => ({
        partialsForm: false,
        seeWarning: false,
        findBOM: false,
        selectedBOM: {},
        processBOM: false,
        processName: 'Bill Of Material',
        projectToFilter: undefined,
        loading: false,
        boms: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 170,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'APPROVED BY',
                value: 'approvedBy.name',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELIVERIES',
                value: 'deliveries',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            production: '#FFC000',
            cancelled: 'red',
            closed: 'primary',
        },
        users: [],
        sendBOMPermission: false,
        projects: [],
        resourceId: undefined,
        rejectPartialsForm: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        rejectLoading: false,
        rejectedNotes: null,
        rejectionTable: false,
    }),
    computed: {
        ...mapState(['notificationResource']),
        ...mapState(['bomUpdates']),
        bomsFiltered() {
            this.openResource()
            let conditions = []
            if (this.$router.currentRoute.name == 'requested-boms') {
                conditions.push(this.filterRequested)
            }
            if (this.search) {
                conditions.push(this.filterBOM)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.boms.filter(bom => {
                    return conditions.every(condition => {
                        return condition(bom)
                    })
                })
            }
            this.sort()
            return this.boms
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
        bomUpdates: function(data) {
            if (data) {
                this.UpdateBom(data)
                this.setBomUpdates(undefined)
            }
        },
    },
    provide() {
        return {
            addDeliveries: this.addDeliveries,
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            await this.loadSecondaryData()
            this.boms = await API.getBOMsChangelog({ status: ['approved'] })
            this.boms.sort((a, b) => {
                const operator1 = a.deliver ? 1 : 0
                const operator2 = b.deliver ? 1 : 0
                return operator2 - operator1
            })
            for (const bom of this.boms) {
                bom.project = this.projects.find(
                    project => project.id == bom.projectId
                )
                if (!bom.project) {
                    bom.project = { id: bom.projectId }
                }
                if (bom.project && bom.project.projectManager) {
                    bom.projectManager = this.users.find(
                        user => user.id == bom.project.projectManager
                    )
                }
                bom.creator = this.users.find(user => user.id == bom.createdBy)
                bom.approvedBy = this.users.find(
                    user => user.id == bom.approvedBy
                )
                bom.totalQty = bom.items.reduce(
                    (accumulator, item) => accumulator + item.quantity,
                    0
                )
                bom.totalRemaining = bom.items.reduce(
                    (accumulator, item) => accumulator + item.remaining,
                    0
                )
                bom.totalDelivered = bom.totalQty - bom.totalRemaining
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
            SocketioService.joinRoom(`${this.companyId}-BOM`)
            SocketioService.leaveRoom(`${this.companyId}-WO`)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        ...mapActions(['setBomUpdates']),
        ...mapGetters(['getBomUpdates']),
        async loadSecondaryData() {
            try {
                this.loading = true
                const userId = auth().currentUser.uid
                const {
                    data: { users },
                } = await API.getUsers()
                this.users = users
                const user = this.users.find(user => user.id == userId)
                if (user.permissions.includes('sendBOM')) {
                    this.sendBOMPermission = true
                }
                // retrieve projects
                this.projects = await API.getLiteProjects({ all: true })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        itemRowBackground(item) {
            return item.showAlert
                ? 'background-color: alertRow !important'
                : item.deliver
                ? 'background-color: deliverRow !important'
                : 'style-2'
        },
        async addBOM(bom) {
            try {
                this.loading = true
                const index = this.boms.findIndex(b => b.id == bom.id)
                if (index >= 0) {
                    this.openProcessBOM(this.boms[index])
                } else {
                    bom.project = await API.getProject(bom.projectId)
                    if (!bom.project) {
                        bom.project = { id: bom.projectId }
                    }
                    if (bom.project && bom.project.projectManager) {
                        bom.projectManager = this.users.find(
                            user => user.id == bom.project.projectManager
                        )
                    }
                    bom.creator = this.users.find(
                        user => user.id == bom.createdBy
                    )
                    bom.approvedBy = this.users.find(
                        user => user.id == bom.approvedBy
                    )
                    this.boms.splice(0, 0, bom)
                    this.openProcessBOM(bom)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        replaceItems(partials) {
            try {
                const bomIndex = this.boms.findIndex(
                    bom => bom.id == this.selectedBOM.id
                )
                for (const partial of partials.requests) {
                    const index = this.boms[bomIndex].items.findIndex(
                        item => item.id == partial.id
                    )
                    if (index >= 0) {
                        this.boms[bomIndex].items[index].remaining =
                            partial.remaining
                        this.boms[bomIndex].items[index].requests =
                            (this.boms[bomIndex].items[index].requests
                                ? this.boms[bomIndex].items[index].requests
                                : 0) + partial.quantity
                    }
                }
                this.boms[bomIndex].deliver = true
                this.boms.sort((a, b) => {
                    const operator1 = a.deliver ? 1 : 0
                    const operator2 = b.deliver ? 1 : 0
                    return operator2 - operator1
                })
                this.boms = [...this.boms]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        addDeliveries(items) {
            try {
                const bomIndex = this.boms.findIndex(
                    bom => bom.id == this.selectedBOM.id
                )
                this.boms[bomIndex].items = items
                this.boms[bomIndex].deliver = items.find(
                    item => item.requests > 0
                )
                    ? true
                    : false
                this.boms.sort((a, b) => {
                    const operator1 = a.deliver ? 1 : 0
                    const operator2 = b.deliver ? 1 : 0
                    return operator2 - operator1
                })
                this.boms = [...this.boms]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        validateQuantity(bom) {
            let conditions = [
                item => {
                    return item.quantity > 0
                },
            ]
            const response = bom.items.filter(item => {
                return conditions.every(condition => {
                    return condition(item)
                })
            })
            return response.length > 0
        },
        partialsAlert() {
            this.activateAlert = true
            this.alertMessage =
                'Partial quantities have been recorded correctly.'
        },
        closePartials() {
            this.selectedBOM = {}
            this.partialsForm = false
        },
        openPartials(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.partialsForm = true
        },
        sendToDeliver() {
            this.processBOM = false
        },
        closeWarningForDelivery() {
            this.seeWarning = false
        },
        openWarningForDelivery(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.seeWarning = true
        },
        sendForDelivery() {
            this.seeWarning = false
        },
        filterBOM(bom) {
            return bom.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterRequested(bom) {
            return bom.deliver == true
        },
        closeBOM() {
            this.boms = this.boms.filter(bom => bom.id != this.selectedBOM.id)
            this.selectedBOM = {}
            this.processBOM = false
        },
        openFindBOM() {
            this.findBOM = true
        },
        closeFindBOM() {
            this.findBOM = false
        },
        openProcessBOM(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.processBOM = true
        },
        closeProcessBOM() {
            const bomIndex = this.boms.findIndex(
                b => b.code == this.selectedBOM.code
            )
            if (bomIndex > -1) {
                this.boms[bomIndex] = this.selectedBOM
            }
            this.boms = _.cloneDeep(this.boms)
            this.selectedBom = {}
            this.processBOM = false
        },
        filterProject(bom) {
            if (!bom.project.name && !bom.project.reference) {
                return false
            }
            return (
                bom.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                bom.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        approve() {
            this.activateAlert = true
            this.alertMessage = 'BOM Successfully Approved'
            this.closeProcessBOM()
        },
        sort() {
            this.boms.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.boms.find(b => b.id == this.resourceId)
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessBOM(resource)
                }
            }
        },
        async UpdateBom(bom) {
            try {
                const index = this.boms.findIndex(b => b.id == bom.bomId)
                if (index >= 0) {
                    if (bom.status == 'approved') {
                        if (bom.items) {
                            bom.totalQty = bom.items.reduce(
                                (accumulator, item) =>
                                    accumulator + item.quantity,
                                0
                            )
                            bom.totalRemaining = bom.items.reduce(
                                (accumulator, item) =>
                                    accumulator + item.remaining,
                                0
                            )
                            bom.totalDelivered =
                                bom.totalQty - bom.totalRemaining
                        }
                        const updatedBom = {
                            ...this.boms[index],
                            ...bom,
                        }
                        this.boms.splice(index, 1, updatedBom)
                        if (this.selectedBOM.id == bom.bomId) {
                            this.selectedBOM = _.cloneDeep(this.boms[index])
                        }
                    } else {
                        this.boms = this.boms.filter(b => b.id != bom.bomId)
                    }
                } else if (bom.status == 'approved') {
                    const newBom = await API.getBoms({
                        id: bom.bomId,
                    })
                    for (let bom of newBom) {
                        bom.project = this.projects.find(
                            project => project.id == bom.projectId
                        )
                        if (!bom.project) {
                            bom.project = { id: bom.projectId }
                        }
                        if (bom.project && bom.project.projectManager) {
                            bom.projectManager = this.users.find(
                                user => user.id == bom.project.projectManager
                            )
                        }
                        bom.creator = this.users.find(
                            user => user.id == bom.createdBy
                        )
                        bom.approvedBy = this.users.find(
                            user => user.id == bom.approvedBy
                        )
                        bom.totalQty = bom.items.reduce(
                            (accumulator, item) => accumulator + item.quantity,
                            0
                        )
                        bom.totalRemaining = bom.items.reduce(
                            (accumulator, item) => accumulator + item.remaining,
                            0
                        )
                        bom.totalDelivered = bom.totalQty - bom.totalRemaining
                        this.boms.push(bom)
                        if (this.selectedBOM.id == bom.bomId) {
                            this.selectedBOM = _.cloneDeep(bom)
                        }
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },

        closeRejectPartials() {
            this.selectedBOM = {}
            this.rejectPartialsForm = false
        },
        openRejectPartials(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.rejectPartialsForm = true
        },

        async rejectPartials() {
            try {
                this.rejectLoading = true
                await API.rejectBOMPartials({
                    id: this.selectedBOM.id,
                    workOrderId: this.selectedBOM.workOrderId,
                    rejectedNotes: this.rejectedNotes,
                })
                this.rejectedNotes = null
                this.rejectPartialsForm = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.rejectLoading = false
            }
        },

        async openRejected(bom) {
            this.selectedBOM = bom
            this.rejectionTable = true
            if (this.selectedBOM.showAlert) {
                await API.hideBomALert({
                    workOrderId: this.selectedBOM.workOrderId,
                    bomId: this.selectedBOM.id,
                })
                this.selectedBOM.showAlert = false
            }
        },

        closeRejected() {
            this.selectedBOM = {}
            this.rejectionTable = false
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
